import { currentService } from '~/lib/service-detector';

export const Route = {
  About: '/about',
  Collections: '/collections',
  Home: currentService() === 'RobinFeed' ? '/home' : '/',
  Login: '/login',
  WaitingList: '/waiting',
  NotFound: '/404',
  Maintenance: '/maintenance',
  Create: '/create',
  Edit: '/edit',
  Error: '/error',
  Preset: '/preset',
  View: '/view',
  TV: '/tv',
  Live: '/live',
  Landing: '/',
};

export enum FeedState {
  Published = 'published',
  Ready = 'ready',
  Draft = 'draft',
}

export enum FeedOperator {
  AND = 'AND',
  OR = 'OR',
}

export enum RobinBtnStyle {
  Primary = 'primary',
  Outline = 'outline',
  Destructive = 'destructive',
  Disabled = 'disabled',
}

export enum NavBtnStyle {
  Transparent = 'transparent',
  Default = 'default',
  Active = 'active',
  Danger = 'danger',
}

export enum ActionMenuItemStyle {
  Default = 'default',
  Active = 'active',
  Danger = 'danger',
}

export enum ErrorActionType {
  Retry = 'retry',
  Back = 'back',
  Home = 'home',
}

export enum Shape {
  Circle = 'circle',
  Square = 'square',
}

export enum Filter {
  Search = 'excludeSearch',
  Hashtags = 'excludeHashtags',
  Authors = 'excludeAuthors',
  Mentions = 'excludeMentions',
}

export enum Preset {
  Search = 'search',
  Hashtag = 'hashtag',
  Author = 'author',
  Mention = 'mention',
  Advanced = 'advanced',
  Collection = 'collection',
}

export enum ToastType {
  Error = 'error',
}
