export const currentService = (): 'RobinFeed' | 'RobinLive' => {
  const values = {
    RobinLive: ['robinlive', 'robin.live'],
    RobinFeed: ['robinfeed', 'robinfeed.com'],
  };
  const fallback = 'RobinLive';
  if (typeof window !== 'undefined')
    return (Object.keys(values).find((key) => values[key as keyof typeof values].some((value) => window.location.hostname.includes(value))) as 'RobinFeed' | 'RobinLive') || fallback;
  return fallback;
};
